import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@egaranti/components";

import AutoCompleteInput from "../SelectProductName";

import PriceInputPrice from "@/components/common/PriceInputBox";

import { withMask } from "use-mask-input";

const DetailsStep = ({ control, watch, setValue, t }) => {
  return (
    <div className="space-y-4">
      <h2 className="text-xl font-semibold">
        {t("productForm.productDetails", "Ürün Bilgileri")}
      </h2>
      <FormField
        control={control}
        name="productName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                "productForm.productNameLabel",
                "Ürünün adı veya modeli nedir?",
              )}
            </FormLabel>
            <FormControl>
              {/* <Input {...field} /> */}
              <AutoCompleteInput
                value={field.value}
                setValue={field.onChange}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="purchaseDate"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t(
                "productForm.purchaseDateLabel",
                "Ürünü ne zaman satın aldınız?",
              )}
            </FormLabel>
            <FormControl
              ref={withMask({
                mask: "99/99/9999",
              })}
            >
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="productPrice"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t("productForm.productPriceLabel", "Ürünün fiyatı nedir?")}
            </FormLabel>
            <FormControl>
              <PriceInputPrice
                onChangeUnit={(value) => setValue("priceUnit", value)}
                onChange={(value) => field.onChange(value)}
                valueUnit={watch("priceUnit")}
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={control}
        name="billFile"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              {t("productForm.uploadBill", "Faturanızı yüklemek için tıklayın")}
            </FormLabel>
            <FormControl>
              <Input
                type="file"
                onChange={(e) =>
                  field.onChange(e.target.files ? e.target.files[0] : null)
                }
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export default DetailsStep;
